import { React } from "react";
import PaginaPrincipal from "../../components/PaginaPrincipal";
import "bootstrap/dist/css/bootstrap.min.css";

const HomePage = () => {
  return (
    <>
      <main className="main bg-white">
        <PaginaPrincipal />
      </main>
    </>
  );
};

export default HomePage;
