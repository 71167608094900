import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import Error from "./components/Error";

const AppRoutes = () => {

    return(
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage />}/>
                <Route exact path="/*" element={<Error />} />
            </Routes>
        </Router>
    )
}

export default AppRoutes;